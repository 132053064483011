.account-settings-container {
  background: #FFF;
  padding: 24px;
  margin-top: 16px;
}

.header-text {
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  color: #001f4e;
}

.subheader-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #86888b;
}

.setting-text {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.71;
  color: #000000;
}

.setting-icon-text {
  font-family: Lato;
  margin: auto;
  font-size: 14px;
  line-height: 1;
  color: #86888b;
}

.account-setting-dropdown-button {
  padding: 6px 8px;
  border: 1px solid #a9aaac;
  border-radius: 4px;
  height: 40  px;
  display: flex;
  min-width: 200px;
}

.account-setting-dropdown-button:hover {
  border: 1px solid #a9aaac;
}

.dropdown-toggle::after {
  margin: auto;
}

/*
.dropdown-toggle {
  display: flex;
  width: 80%;
  height: 40px;
  padding: 6px 8px;
  border-radius: 4px;
  border: 1px solid #a9aaac;
}

.show>.btn-primary.dropdown-toggle {
  padding: 6px 8px;
  border-radius: 4px;
  border: solid 1px #a9aaac;
  background-color: #FFF;
}

.btn-primary:focus {
  background-color: #FFF;
}

.dropdown-button {
  width: 100%;
}

.dropdown-menu.show {
  width: 80%;
  padding-left: 12px;
  padding-right: 12px;
} */
